<template>
  <section class="section default-full-screen-top no-padding-phone">
    <div class="container">
      <section class="hero is-primary banner">
        <div class="hero-body">
          <div class="container has-text-centered">
            <h1>Pixivel</h1>
          </div>
        </div>
        <div class="hero-foot">
          <nav class="tabs">
            <div class="container">
              <ul class="is-justify-content-center link-tabs">
                <li :class="{ 'link-active': tabNow == 0 }" @click="tabNow = 0">
                  <a>主页</a>
                </li>

                <li :class="{ 'link-active': tabNow == 5 }" @click="tabNow = 5">
                  <a>历史记录</a>
                </li>

                <li :class="{ 'link-active': tabNow == 1 }" @click="tabNow = 1">
                  <a>下载</a>
                </li>
                <li :class="{ 'link-active': tabNow == 2 }" @click="tabNow = 2">
                  <a>设置</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
      <section v-if="tabNow == 0">
        <h3 class="title is-3">公告</h3>
        <b-message
          v-for="(value, key) in announce"
          :key="key"
          type="is-success"
        >
          <div v-html="value"></div>
        </b-message>
      </section>
      <section v-else-if="tabNow == 1">
        <div class="notification" v-if="!hasDownloadTask">
          没有正在进行的下载任务。
        </div>
        <div class="columns set-list">
          <div
            class="column is-half-desktop is-half-tablet"
            v-for="(progress, iden) in downloadingTasks"
            :key="iden"
          >
            <b-notification :closable="false">
              <b-taglist>
                <b-tag type="is-link"
                  >TYPE: {{ JSON.parse(iden)["type"] }}</b-tag
                >
                <b-tag type="is-info">ID: {{ JSON.parse(iden)["id"] }}</b-tag>
                <b-tag type="is-info"
                  >PAGE: {{ JSON.parse(iden)["page"] }}</b-tag
                >
              </b-taglist>
              <b-progress
                type="is-success"
                :value="progress * 100"
                show-value
              ></b-progress>
            </b-notification>
          </div>

          <div
            class="column is-half-desktop is-half-tablet"
            v-for="(task, index) in downloadTasks"
            :key="JSON.stringify(task) + '.task'"
          >
            <b-notification @close="removeTask(index)">
              <b-taglist>
                <b-tag type="is-link">TYPE: {{ task["type"] }}</b-tag>
                <b-tag type="is-info">ID: {{ task["id"] }}</b-tag>
                <b-tag type="is-info">PAGE: {{ task["page"] }}</b-tag>
              </b-taglist>
              <b-progress type="is-success" show-value></b-progress>
            </b-notification>
          </div>
        </div>
      </section>
      <section v-else-if="tabNow == 2">
        <br />
        <div class="columns set-list">
          <div class="column is-one-third-desktop is-full-mobile">
            <b-field label="下载文件名格式">
              <b-input
                :value="getdownloadfilename"
                @blur="setdownloadfilename"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-one-third-desktop is-full-mobile">
            <b-field label="首页推荐敏感度过滤">
              <b-numberinput
                :controls="false"
                :value="isLoggedIn ? getsamplesanityfilter : 2"
                :min="2"
                :max="8"
                @blur="setsamplesanityfilter"
              ></b-numberinput>
            </b-field>
          </div>
          <div class="column is-one-third-desktop is-full-mobile">
            <b-field label="全局敏感度过滤">
              <b-numberinput
                :controls="false"
                :value="isLoggedIn ? getglobalsanityfilter : 2"
                :min="2"
                :max="8"
                @blur="setglobalsanityfilter"
              ></b-numberinput>
            </b-field>
          </div>
        </div>
        <div class="columns set-list">
          <div class="column is-one-third-desktop is-full-mobile">
            <b-field label="推荐热门度筛选">
              <b-numberinput
                :controls="false"
                :value="getrecommendquality"
                :min="0"
                :max="400000"
                @blur="setrecommendquality"
              ></b-numberinput>
            </b-field>
          </div>
          <div class="column is-one-third-desktop is-full-mobile">
            <b-field label="过滤所有AI作品">
              <b-switch @input="setaifilter" :value="getaifilter"></b-switch>
            </b-field>
          </div>
        </div>
      </section>
      <section v-else-if="tabNow == 3">
        <Bookmark />
      </section>
      <section v-else-if="tabNow == 4">
        <Follow />
      </section>
      <section v-else-if="tabNow == 5">
        <History />
      </section>
    </div>
  </section>
</template>

<script>
import storage from "store2";
import History from "@/components/history";
import Bookmark from "@/components/bookmark";
import Follow from "@/components/follow";

const AnnounceKey = "announce";

export default {
  name: "Account",
  components: {
    History,
    Bookmark,
    Follow,
  },
  data() {
    return {
      tabNow: 0,
      announce: window.JSON.parse(storage.get(AnnounceKey, "{}")),
      defaultAvatar: "img/defaultAva.jpg",
    };
  },
  watch: {
    tabNow() {
      if ("#" + this.tabNow.toString() == this.$route.hash) return;
      this.$router.replace({
        name: "Account",
        hash: "#" + this.tabNow.toString(),
      });
    },
    $route() {
      if ("#" + this.tabNow.toString() == this.$route.hash) return;
      this.tabNow = parseInt(this.$route.hash.split("#")[1]);
    },
  },
  created() {
    if (this.$route.hash.length > 1) {
      this.tabNow = parseInt(this.$route.hash.split("#")[1]);
    }
  },
  computed: {
    downloadTasks() {
      return this.$store.state.Pic.download.queue;
    },
    downloadingTasks() {
      return this.$store.state.Pic.download.downloadingQueue;
    },
    hasDownloadTask() {
      return (
        Object.keys(this.$store.state.Pic.download.downloadingQueue).length >
          0 || this.$store.state.Pic.download.queue.length > 0
      );
    },
    getdownloadfilename() {
      return this.$store.getters["Settings/get"]("download.filename");
    },
    getsamplesanityfilter() {
      return this.$store.getters["Settings/get"]("sample.sanity_filter");
    },
    getglobalsanityfilter() {
      return this.$store.getters["Settings/get"]("global.sanity_filter");
    },
    getrecommendquality() {
      return this.$store.getters["Settings/get"]("recommend.quality");
    },
    getaifilter() {
      return this.$store.getters["Settings/get"]("global.ai_filter");
    },
  },
  methods: {
    removeTask(index) {
      this.$store.commit("Pic/removeDownloadTask", index);
    },
    setdownloadfilename(e) {
      this.$store.commit("Settings/set", {
        key: "download.filename",
        value: e.target.value,
      });
    },
    setsamplesanityfilter(e) {
      this.$store.commit("Settings/set", {
        key: "sample.sanity_filter",
        value: parseInt(e.target.value),
      });
    },
    setglobalsanityfilter(e) {
      this.$store.commit("Settings/set", {
        key: "global.sanity_filter",
        value: parseInt(e.target.value),
      });
    },
    setrecommendquality(e) {
      this.$store.commit("Settings/set", {
        key: "recommend.quality",
        value: parseInt(e.target.value),
      });
    },
    setaifilter(v) {
      this.$store.commit("Settings/set", {
        key: "global.ai_filter",
        value: v,
      });
    },
    jump(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss">
.banner {
  border-radius: 10px;
  margin-bottom: 2rem;

  .message {
    transition: none;
  }

  .hero-body {
    padding: 2rem 2rem;
  }
  .link-tabs {
    li {
      transition: all 0.2s ease-in-out;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .link-active {
      background-color: #fff;
      color: #009e86;
    }
  }
}

.media {
  align-items: center;
}

.set-list {
  flex-wrap: wrap;
}

.hover-up:hover {
  transform: translateY(-5px) scale(1.02);
}

.hover-up {
  transition: transform 0.1s ease-in-out;
}
</style>

<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        Deployed by Cat
      </p>
    </div>
  </footer>
</template>

<script>

export default {
  name: "Footer",
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.footer {
  padding: 3rem 1.5rem !important;
}
</style>
